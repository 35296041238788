<template>
    <div>
        <van-nav-bar title="会议手册" left-arrow @click-left="onClickLeft" />
        <van-divider style="color: #6e6e6e; border-color: #1989fa; font-size: 16px;">
            点击图片，可进行预览缩放
        </van-divider>
        <van-image width="100%" v-for="(item,index) in images" :key="index" :src="item" @click="onImgClick(index)"/>
       
        <van-image-preview v-model="showPreview" :startPosition="index" :images="images"  @change="onChange">
            <template slot="index">第{{ index+1 }}页</template>
        </van-image-preview>
    </div>
</template>

<script>
export default {
    data() {
        return {
            index:0,
            showPreview: false,
            images: [
                "/shouce/shouce1.jpg",
                "/shouce/shouce2.jpg",
                "/shouce/shouce3.jpg",
                "/shouce/shouce4.jpg",
                "/shouce/shouce5.jpg"
            ],
        }
    },
    methods: {
        onClickLeft() {
            this.$router.push('/')
        },
        onImgClick(index){
            this.index=index
            this.showPreview=true
        },
        onChange(index) {
            this.index = index;
        }
    }
};
</script>

<style scoped></style>
